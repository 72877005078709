export async function localizeText(message, lang) {
  if (!lang) lang = getLang() || "en";
  const localized = await fetch(`/api/localize?message=${message}&lang=${lang}`);
  const json = await localized.json();
  return json.localized;
}

export function initToasts() {
  if (typeof bootstrap === "undefined") return;

  try {
    const toastElList = [].slice.call(document.querySelectorAll(".toast"));
    const toastList = toastElList.map(function (toastEl) {
      return new bootstrap.Toast(toastEl);
    });
  } catch (e) {
    // console.log(e);
  }
}

export function initTooltips() {
  destroyHangingTooltips();
  unshowTooltips();
  if (typeof bootstrap === "undefined") return;

  const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
  const tooltipList = [...tooltipTriggerList].map(
    (tooltipTriggerEl) =>
      new bootstrap.Tooltip(tooltipTriggerEl, {
        trigger: "hover",
      })
  );
}

function unshowTooltips() {
  // Get all elements with the class "tooltip"
  var tooltips = document.getElementsByClassName("tooltip");

  // Loop through the array of tooltips
  for (var i = 0; i < tooltips.length; i++) {
    // Hide the tooltip
    tooltips[i].style.display = "none";
  }
}

export function manuallyStoreEntries() {
  const allInputAndTextAreas = document.querySelectorAll("div[contentEditable='true'], input, textarea");
  if (!allInputAndTextAreas) return;
  allInputAndTextAreas.forEach((el) => {
    if (el.type === "password") return;
    if (el.type === "email") return;
    store(el);
  });
}

export function getLang() {
  // A URL like /da/docs/63f8957b98f69d9cdd5b1802 would return "da"
  const lang = window.location.pathname.split("/")[1] || "en";
  return lang;
}

export function storeEntries() {
  const allInputAndTextAreas = document.querySelectorAll("div[contentEditable='true'], input, textarea");
  if (!allInputAndTextAreas) return;
  allInputAndTextAreas.forEach((el) => {
    if (el.type === "password") return;
    if (el.type === "email") return;

    el.addEventListener("keyup", () => store(el));
    el.addEventListener("click", () => store(el));
    el.addEventListener("change", () => store(el));
  });
}

function purge(html) {
  if (!html) return;
  html = html.replace(/<span.*?>|<\/span>/g, ""); // Remove all <span> tags
  html = html.replace(/<del>.*?<\/del>/g, ""); // Removes <del> ... </del>
  html = html.replace(/<ins.*?>|<\/ins>/g, ""); // Removes only the <ins> tags
  return html;
}

function unpurge(html) {
  if (!html) return;
  html = html.replace(/<span.*?>|<\/span>/g, "");
  html = html.replace(/<ins>.*?<\/ins>/g, ""); // Removes <ins> ... </ins>
  html = html.replace(/<del.*?>|<\/del>/g, ""); // Removes only the <del> tags
  return html;
}

// 📦 Store the value of an element in local storage
function store(el) {
  if (el.tagName === "TEXTAREA" || el.tagName === "INPUT") {
    let val = purge(el.value);
    if (!val) val = "";
    localStorage.setItem(el.id, val);
  } else {
    if (el.innerText.length <= 1) {
      localStorage.setItem(el.id, "");
    } else {
      let val = purge(el.innerHTML);
      if (!val) val = "";
      localStorage.setItem(el.id, val);
    }
  }
}

// On load bring topic etc back from localstorage
export function recallEntries() {
  const allInputAndTextAreas = document.querySelectorAll("div[contentEditable='true'], div[contenteditable='true'],input, textarea");
  if (!allInputAndTextAreas) return;

  allInputAndTextAreas.forEach((el) => {
    if (el.id === "results") return;

    if (el.tagName === "TEXTAREA" || el.tagName === "INPUT") {
      // If the el has the property skip, then skip it
      if (el.dataset.skip === "true") {
        console.log("Skipping", el.id);
        return;
      }

      // If it is already containing something, then skip it
      if (el.value) return;
      let valueInStorage = localStorage.getItem(el.id);
      if (!valueInStorage) valueInStorage = "";
      if (valueInStorage === "undefined") valueInStorage = "";

      valueInStorage = valueInStorage ? valueInStorage.replaceAll("undefined", "") : "";
      valueInStorage = valueInStorage ? valueInStorage.replaceAll("<br>", "\n") : "";
      valueInStorage = valueInStorage ? valueInStorage.replaceAll("</br>", "\n") : "";

      el.value = valueInStorage;
    } else {
      if (el.innerText) return;
      let valueInStorage = localStorage.getItem(el.id);
      if (!valueInStorage) valueInStorage = "";
      if (valueInStorage === "undefined") valueInStorage = "";

      el.innerHTML = valueInStorage;
    }
  });
}

export function handleErrors(error) {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.log(error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log("Error", error.message);
  }
  console.log(error.config);
}

export async function showWarning(optionalText = "", delay = 5000) {
  if (typeof bootstrap === "undefined") return;

  const warningToast = document.querySelector("#warningToast");

  // if optionaltext is an object, extract the messaage compnonent
  if (typeof optionalText === "object") optionalText = optionalText.message;

  const lang = getLang();

  try {
    if (optionalText && optionalText.length > 0) {
      if (lang !== "en") {
        optionalText = await localizeText(optionalText, lang);
      }

      warningToast.querySelector(".toast-body").innerHTML = optionalText;
      warningToast.querySelector("#timing").innerText = new Date().toLocaleTimeString();
    }

    if (!warningToast) {
      alert(optionalText);
      return;
    }

    const newToast = new bootstrap.Toast(warningToast, { delay: delay });
    newToast.show();
  } catch (e) {
    console.log(e);
  }
}

export async function showSuccess(optionalText = "", delay = 2500) {
  if (typeof bootstrap === "undefined") return;

  const successToast = document.querySelector("#successToast");

  // if optionaltext is an object, extract the messaage compnonent
  if (typeof optionalText === "object") optionalText = optionalText.message;

  try {
    if (optionalText.length > 0) {
      const lang = getLang();
      if (lang !== "en") {
        optionalText = await localizeText(optionalText, lang);
      }

      successToast.querySelector(".toast-body").innerHTML = optionalText;
      successToast.querySelector("#timing").innerText = new Date().toLocaleTimeString();
    }

    if (!successToast) {
      alert(optionalText);
      return;
    }

    const newToast = new bootstrap.Toast(successToast, { delay });
    newToast.show();
  } catch (e) {
    console.log(e);
  }
}

export function destroyHangingTooltips() {
  try {
    const tooltips = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    tooltips.forEach((tooltip) => {
      const tooltipInstance = bootstrap.Tooltip.getInstance(tooltip);
      if (tooltipInstance._isOpen) {
        tooltipInstance.hide();
        tooltipInstance.dispose();
      }
    });
  } catch (e) {
    // console.log(e);
  }
}

export async function postRequest(url, data, timeout = 25000) {
  // Remove all queries and hashes from the url
  url = url.split("?")[0].split("#")[0];

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
      signal: controller.signal,
    });

    clearTimeout(id);

    try {
      return await response.json();
    } catch (error) {
      console.error(`Error parsing response body: ${error}`);
      return { error: "Error parsing response body" };
    }
  } catch (error) {
    if (error.name === "AbortError") {
      console.error(`Request timed out after ${timeout}ms`);
      showWarning("It took too long to process your request. Please try again later.");
      return { error: "Request timed out" };
    } else {
      console.error(`Request failed: ${error}`);
      return { error: "Request failed" };
    }
  }
}

export function getAllFields() {
  const arr = [];
  // get all fields of input, textarea, and contenteditable by their ids
  const allInputAndTextAreas = document.querySelectorAll("div[contentEditable='true'], input, textarea");
  arr.push(...[].slice.call(allInputAndTextAreas).map((el) => el.id));
  return arr;
}

export function getAllFieldsByReq() {
  const reqArr = [];
  const optArr = [];

  const requiredFields = document.querySelectorAll("[required]");
  reqArr.push(...[].slice.call(requiredFields).map((el) => el.id));

  const optionalFields = document.querySelectorAll("[optional]");
  optArr.push(...[].slice.call(optionalFields).map((el) => el.id));

  return { reqArr, optArr };
}

// Add watermark to copy-paste
// TODO Remove watermark on pro accounts
export function addWatermarkToCopyPaste() {
  document.addEventListener("copy", (event) => {
    const pagelink = ""; //`\n\nMed ❤️ fra TekstGuru`;
    event.clipboardData.setData("text/plain", document.getSelection() + pagelink);
    event.preventDefault();
  });
}

export function selectionSpy() {
  document.addEventListener("selectionchange", (event) => {
    localStorage.currentSelection = window.getSelection().toString();
  });
}

// Generic activator
export async function enableFunction(btnId, resultsId, endpoint, input = "text", wordLimit = 300) {
  // Check if logged in
  const btn = document.getElementById(btnId);
  console.log({ btn });

  if (!btn) return;

  const results = document.getElementById(resultsId);

  // Remove any purple underlining
  removeMarkup();

  // Mousedown instead of click to avoid losing selection
  btn.addEventListener("mousedown", async function () {
    try {
      // 🚧 If results is empty, then return
      if (results.innerText === "") throw new Error("Du skal generere (eller selv skrive) tekst i feltet først");

      // 🚧 Check if there are more than wordLimit words and 0 words are selected
      const words = results?.innerText.split(" ") || [];
      const selectedText = localStorage.currentSelection;
      const selectedTextArray = selectedText ? selectedText.split(" ") : [];

      if (words.length > wordLimit && selectedTextArray.length <= 1) throw new Error(`Vælg op til ${wordLimit} ord ad gangen. Det kan du gøre ved at markere teksten med din mus og så klikke på knappen igen.`);

      // 🚧 Check if more than wordLimit words are selected
      if (selectedTextArray.length > wordLimit) throw new Error(`Vælg op til ${wordLimit} ord ad gangen`);

      // 🚧 Check if only one word is selected OR only one word is in results
      if (selectedTextArray.length === 1 && results.innerText.trim().length <= 1) throw new Error("Vælg mere end et enkelt ord ad gangen");

      lockPage();

      let range = null;
      let documentFragment = null;
      let fragmentText = null;
      let htmlNode = null;
      let useSelectedText = false;

      // Get user selection of text, if any.
      let selection = window.getSelection();
      const selectionString = selection.toString();
      const selectionStringLength = selectionString.length;

      // If there is a selection, use it
      if (selection && selection.rangeCount > 0 && selectionStringLength > 0) {
        range = selection.getRangeAt(0);

        // Get content
        const currentContent = range.cloneContents();
        const currentContentFragment = currentContent.textContent;

        // Extract text and replace with htmlNode
        documentFragment = range.extractContents();

        // Create a text node with the random string
        htmlNode = document.createElement("span");

        // Add class to htmlNode, and the random string
        htmlNode.className = "blur"; // Make scrambling effect instead?

        // Create an id with a random string of numbers
        // let spanId = generateRandomString(10); // TODO
        // Ahh, to have more than one span workign at the same time?
        htmlNode.id = "tempSpan";

        // Replace with original text, but blurred
        htmlNode.innerHTML = currentContentFragment;
        range.insertNode(htmlNode);

        fragmentText = documentFragment.textContent;
        useSelectedText = true;
      }

      // 🌫 Add blur to indicate loading
      if (!useSelectedText) results.classList.add("blur");

      // 🔐 Remove contenteditable
      results.contentEditable = false;

      // Get full text, unless some text is selected
      let text = results.innerText;
      if (useSelectedText) text = fragmentText;

      // POST to server
      const data = { text };

      const query = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };

      const response = await fetch(endpoint, query);
      console.log("Response received", { response });

      if (!response) throw new Error("Vi mistede forbindelsen til serveren. Er du online?");
      if (response.error) throw new Error(response.error);

      const json = await response.json();

      // Check response text (sometimes in results, sometimes in text)
      let responseText = null;
      if (json.results) responseText = json.results;
      if (json.text) responseText = json.text;
      console.log("Reponse text", { responseText });

      if (!responseText) throw new Error("Der opstod en uventet fejl. Prøv igen senere.");
      if (Array.isArray(responseText) && responseText.length === 1) responseText = responseText[0];

      // Get the "new" pure text version
      const tempSpan = document.getElementById("tempSpan");

      if (useSelectedText) {
        tempSpan.innerHTML = responseText;
        localStorage.new = results.innerText;
      }

      if (!useSelectedText) localStorage.new = responseText;

      // Get diffs
      let diffs = null;
      if (json.diffs) diffs = json.diffs;
      console.log("Diffs", { diffs: json.diffs });

      // Wrap response text in span
      if (diffs) responseText = diffs.html;
      if (!diffs) responseText = `<span class='purple'>${responseText}</span> `;

      // Show buttons to accept or reject changes
      acceptChanges();

      // Save the purged version to server and LS
      localStorage.results = purge(results.innerHTML);
      console.log("Saved purged version to LS ...");

      // Replace the text with the new text, possibly a diff
      if (useSelectedText) tempSpan.outerHTML = responseText;
      if (!useSelectedText) results.innerHTML = responseText;

      // 🌈 Send happy thoughts
      showSuccess(10000, "Teksten er blevet opdateret ✨");
    } catch (e) {
      // results.innerText = localStorage.backup;

      // Capture errors
      console.error(e);
      sendErrorToServer(`Error in ${btnId}. ${e}`);

      // 😢 Send unhappy thoughts
      showWarning(e.message);
    } finally {
      unlockPage();
    }
  });
}

// Generic rewriter
export function enableRewriteBtn(btnId = "rewriteText", resultsId = "results") {
  enableFunction(btnId, resultsId, "/t/omskriv", "text", 300);
  return;
}

// Haya rewriter
export function enableHayaBtn(autoSaveTimer = null, btnId = "hayaRewrite", resultsId = "results") {
  if (autoSaveTimer) clearInterval(autoSaveTimer);
  enableFunction(btnId, resultsId, "/t/haya", "text", 300);

  if (autoSaveTimer) return setInterval(autoSave, 10000); // Auto-save every 10 seconds
  else return;
}

// Commas
export function enableFixCommas(autoSaveTimer = null, btnId = "fixCommas", resultsId = "results") {
  enableFunction(btnId, resultsId, "/t/komma", "text", 300);
}

// Simplify
export async function enableSimplifyText(autoSaveTimer = null, btnId = "simplifyText", resultsId = "results") {
  if (autoSaveTimer) clearInterval(autoSaveTimer);
  await enableFunction(btnId, resultsId, "/t/simplificer-saetninger", "text", 1200);
  if (autoSaveTimer) return setInterval(autoSave, 10000); // Auto-save every 10 seconds
  else return;
}

// Expander
export function enableTextExpander(autoSaveTimer = null, btnId = "textExpander", resultsId = "results") {
  if (autoSaveTimer) clearInterval(autoSaveTimer);
  enableFunction(btnId, resultsId, "/t/tekst-forlaenger", "text", 600);
  if (autoSaveTimer) return setInterval(autoSave, 10000); // Auto-save every 10 seconds
  else return;
}

// Improver
export function enableTextImprover(autoSaveTimer = null, btnId = "textImprover", resultsId = "results") {
  if (autoSaveTimer) clearInterval(autoSaveTimer);
  enableFunction(btnId, resultsId, "/t/forbedrer", "text", 600);
  if (autoSaveTimer) return setInterval(autoSave, 10000); // Auto-save every 10 seconds
  else return;
}

// Remove markup
export function enableRemoveMarkup(autoSaveTimer = null, preWrap = true) {
  if (autoSaveTimer) clearInterval(autoSaveTimer);
  const removeMarkupBtn = document.querySelector("#clearMarkup");
  if (!removeMarkupBtn) return;
  removeMarkupBtn.addEventListener(
    "click",
    function () {
      removeMarkup(preWrap);
    },
    false
  );

  if (autoSaveTimer) return setInterval(autoSave, 10000); // Auto-save every 10 seconds
  else return;
}

// Autofix
export function enableAutofixBtn() {
  const autoFixBtn = document.getElementById("autoFixBtn");
  if (autoFixBtn) autoFixBtn.addEventListener("click", autoFix);

  async function autoFix() {
    try {
      // Get text from results field
      const results = document.getElementById("results");
      results.classList.add("blur");

      const text = results.innerText;

      // Send text to server
      const response = await postRequest("/api/improvify", { text });

      // Check response text (sometimes in results, sometimes in text)
      let responseText = null;
      if (response.hasOwnProperty("results")) responseText = response.results;
      if (response.hasOwnProperty("text")) responseText = response.text;

      // Replace text
      results.innerText = responseText;

      showSuccess(5000, "Teksten er blevet opdateret ✨");
      manuallyStoreEntries();

      results.classList.remove("blur");
    } catch (e) {
      console.log(e);
      sendErrorToServer("Error in autoFix", { e });
      showWarning("Something went wrong. Please try again later");
      results.classList.remove("blur");
    }
  }
}

// Improve this
export async function removeMarkup(preWrap = true) {
  try {
    const results = document.getElementById("results");
    if (!results) return;

    let text = results.innerText;
    // text = purge(text);
    if (preWrap) results.classList.add("preWrap");
    results.innerText = text;
    // });
  } catch (e) {
    console.error(e);
    sendErrorToServer("Error in removeMarkup", e);
  }
}

export async function enableClearAll() {
  const results = document.getElementById("results");
  if (!results) return;
  localStorage.backup = results.innerText;

  try {
    const clearAll = document.getElementById("clearAll");
    if (!clearAll) return;

    clearAll.addEventListener("click", () => {
      const confirm = window.confirm("Er du sikker på, at du vil slette al tekst fra alle felter?");
      if (confirm) {
        const fields = document.querySelectorAll("input,div[contenteditable]");
        fields.forEach((field) => {
          if (field.textContent) field.textContent = "";
          if (field.value) field.value = "";
        });
      }
    });
  } catch (e) {
    console.error(e);
    sendErrorToServer("Error in enableClearAll", e);
    showWarning(10000, "Der gik desværre noget galt ...");
    results.innerText = localStorage.backup;
  }
}

// Avoid pasting in HTML and rich text by replacing it with plain text
export function stripHTMLwhenPasting() {
  const contenteditableFields = document.querySelectorAll("[contenteditable]");
  contenteditableFields.forEach((field) => {
    if (field.dataset.skip) return;

    field.addEventListener("paste", function (e) {
      // cancel paste
      e.preventDefault();

      // get text representation of clipboard
      var text = (e.originalEvent || e).clipboardData.getData("text/plain");

      // replace line breaks with <br> elements
      text = text.replace(/\n/g, "<br>");

      // insert modified text into field
      document.execCommand("insertHTML", false, text);
    });
  });
}

export function initializePopovers() {
  if (typeof bootstrap === "undefined") return;
  const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));

  popoverTriggerList.map((popoverTriggerEl) => {
    const newPopover = new bootstrap.Popover(popoverTriggerEl, {
      trigger: "click hover",
      placement: "auto",
      // template:
      //   '<div class="popover" role="tooltip"><div class="popover-arrow"></div><h3 class="popover-header d-flex"></h3><div class="popover-body"></div></div>',
      html: true,
      animation: false,
      sanitize: false,
    });
  });
}

export function reinitializePopovers() {
  if (typeof bootstrap === "undefined") return;
  const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
  popoverTriggerList.forEach(function (el) {
    const popover = bootstrap.Popover.getInstance(el);
    popover && popover.dispose();
  });

  popoverTriggerList.map((popoverTriggerEl) => {
    const newPopover = new bootstrap.Popover(popoverTriggerEl, {
      trigger: "click hover",
      placement: "auto",
      template: '<div class="popover" role="tooltip"><div class="popover-arrow"></div><h3 class="popover-header d-flex"></h3><div class="popover-body"></div></div>',
      html: true,
      animation: false,
      sanitize: false,
    });
  });
}

export function notifyUser() {
  try {
    if (!("Notification" in window)) {
      // Check if the browser supports notifications
      alert("This browser does not support desktop notification");
    } else if (Notification.permission === "granted") {
      // Check whether notification permissions have already been granted;
      // if so, create a notification
      const notification = new Notification("Voila!");
    } else if (Notification.permission !== "denied") {
      // We need to ask the user for permission
      Notification.requestPermission().then((permission) => {
        // If the user accepts, let's create a notification
        if (permission === "granted") {
          const notification = new Notification("Voila!");
          // …
        }
      });
    }
  } catch (e) {
    console.log(e);
  }
}

export function countWords(domName = "results") {
  let wordCount;

  // Get all words in results;
  const results = document.getElementById(domName);
  if (!results) wordCount = 0;
  else {
    if (results.innerText.length === 0) {
      wordCount = 0;
    } else {
      let text = results.innerText
        .replace(/&amp;/g, "og")
        .replace(/[.,()"'!;\n\r]/g, " ")
        .replace(/&amp;|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/gi, " ")
        .replace("  ", " ");

      let arr = text ? text.split(/\s+/).filter((n) => n !== "" && n !== " ") : [];
      wordCount = arr.length;
    }
  }

  return wordCount;
}

function countSelectedWords() {
  let selectedWordCount;

  // Get all selected words in results;
  const selection = window.getSelection();

  if (selection.toString() === "") selectedWordCount = 0;
  else {
    let text = selection
      .toString()
      .replace(/&amp;/g, "og")
      .replace(/[.,()"'!;\n\r]/g, " ")
      .replace(/&amp;|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/gi, " ")
      .replace("  ", " ");

    let arr = text ? text.split(/\s+/).filter((n) => n !== "") : [];
    selectedWordCount = arr.length || 0;
  }

  return selectedWordCount;
}

export function enableWordCounter(domName = "results") {
  const results = document.getElementById(domName);
  if (!results) return;

  const wordCounter = document.getElementById("wordCounter");
  const selectedWordCounter = document.getElementById("selectedWordCounter");

  if (wordCounter) {
    const wordsAtStart = countWords(domName);
    wordCounter.innerText = wordsAtStart;

    // On keydown
    document.addEventListener("keyup", () => {
      const wordCount = countWords(domName);
      wordCounter.innerText = wordCount;

      if (selectedWordCounter) {
        const selectedWordCount = countSelectedWords();
        selectedWordCounter.innerText = selectedWordCount;
      }
    });
  }

  if (selectedWordCounter) {
    // On select
    function selectionRecorder() {
      const selectedWordCount = countSelectedWords();
      selectedWordCounter.innerText = selectedWordCount;
    }

    results.addEventListener("selectstart", () => {
      document.addEventListener("selectionchange", selectionRecorder);
    });

    results.addEventListener("mouseleave", () => {
      document.removeEventListener("selectionchange", selectionRecorder);
    });

    // Reset counter if user clicks outside of results
    document.addEventListener("click", (e) => {
      if (!results.contains(e.target)) {
        selectedWordCounter.innerText = 0;
      }
    });
  }
}

export function activateTextPanel() {
  // Update stats on load
  setTimeout(() => {
    updateStats();
  }, 1000);
  if (results) results.addEventListener("keydown", updateStats);
}

export function activateTextPanelVanilla() {
  const results = document.getElementById("results");
  if (results) {
    results.addEventListener("keydown", () => {
      const text = results.innerText;
      getTextMetrics(text);
    });
  }
}

export async function getTextMetrics(text) {
  console.log("getTextMetrics");
  if (!text) return;

  const lix = document.querySelectorAll("[lix]"); // TextMetrics
  const words = document.querySelectorAll("[words]"); // TextMetrics
  const sentences = document.querySelectorAll("[sentences]"); // TextMetrics
  const avgSentenceLength = document.querySelectorAll("[avgSentenceLength]"); // TextMetrics
  const paragraphs = document.querySelectorAll("[paragraphs]"); // TextMetrics
  const normalPages = document.querySelectorAll("[normalPages]"); // TextMetrics
  const charsNoSpaces = document.querySelectorAll("[charsNoSpaces]"); // TextMetrics
  const charsWithSpaces = document.querySelectorAll("[charsWithSpaces]"); // TextMetrics
  const readingTime = document.querySelectorAll("[readingTime]"); // TextMetrics
  const speakingTime = document.querySelectorAll("[speakingTime]"); // TextMetrics
  const avgWordLength = document.querySelectorAll("[avgWordLength]"); // TextMetrics

  const longWords = document.querySelectorAll("[longWords]"); // LongWords
  const audience = document.querySelectorAll("[audience]"); // LongWords
  const difficulty = document.querySelectorAll("[difficulty]"); // LongWords

  const hardSentences = document.querySelectorAll("[hardSentences]"); // Sentence Difficulty
  const veryHardSentences = document.querySelectorAll("[veryHardSentences]"); // Sentence Difficulty

  if (!text || text === "\n" || text === "" || text === " ") {
    text = "";

    lix.forEach((el) => (el.innerText = 0));
    words.forEach((el) => (el.innerText = 0));
    sentences.forEach((el) => (el.innerText = 0));
    avgSentenceLength.forEach((el) => (el.innerText = 0));
    avgWordLength.forEach((el) => (el.innerText = 0));

    paragraphs.forEach((el) => (el.innerText = 0));
    normalPages.forEach((el) => (el.innerText = 0));
    charsNoSpaces.forEach((el) => (el.innerText = 0));
    charsWithSpaces.forEach((el) => (el.innerText = 0));
    readingTime.forEach((el) => (el.innerText = 0));
    speakingTime.forEach((el) => (el.innerText = 0));
    difficulty.forEach((el) => (el.innerText = 0));
    audience.forEach((el) => (el.innerText = 0));
    longWords.forEach((el) => (el.innerText = 0));
    return;
  }

  // Get text metrics
  const metrics = postRequest("/api/text-metrics", { text });
  metrics.then((data) => {
    if (data && data.hasOwnProperty("sidebar")) {
      let sidebar = data.sidebar;

      // TextMetrics
      lix.forEach((el) => (el.innerText = sidebar.lix));
      words.forEach((el) => (el.innerText = sidebar.wordCount));
      sentences.forEach((el) => (el.innerText = sidebar.sentenceCount));
      avgSentenceLength.forEach((el) => (el.innerText = sidebar.avgSentenceLength));
      avgWordLength.forEach((el) => (el.innerText = sidebar.avgWordLength));

      paragraphs.forEach((el) => (el.innerText = sidebar.paragraphs));
      normalPages.forEach((el) => (el.innerText = sidebar.normalsider));
      charsNoSpaces.forEach((el) => (el.innerText = sidebar.charsNoSpaces));
      charsWithSpaces.forEach((el) => (el.innerText = sidebar.charsWithSpaces));
      readingTime.forEach((el) => (el.innerText = sidebar.readingTime));
      speakingTime.forEach((el) => (el.innerText = sidebar.speakingTime));
      difficulty.forEach((el) => (el.innerText = sidebar.difficulty));
      audience.forEach((el) => (el.innerText = sidebar.audience));
      longWords.forEach((el) => (el.innerText = sidebar.longWordsCount));
    }
  });
}

export async function updateStats(quill) {
  if (!quill) return;
  let text = quill.getText();

  // Get text metrics
  getTextMetrics(text);
}

export async function updateStatsVanilla() {
  // Check prev against current text, maybe with a set timeout.
  debounce(() => getTextMetrics(text), 300);
}

function debounce(func, wait) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      func.apply(context, args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

// 📝 Save function: POST an update to the server (has to be same endpoint as the GET)
export async function save(headingVal = null, contentVal = null) {
  const content = document.getElementById("results");
  const heading = document.getElementById("heading");
  if (!content && !heading) return;

  if (!headingVal) headingVal = heading?.value;
  if (!contentVal) contentVal = content?.innerText;
  if (!contentVal.length && !headingVal.length) return;

  const url = window.location.href;

  const res = await postRequest(url, { heading: headingVal, content: contentVal });

  if (res.error) showWarning(res.error);
  if (res.success) console.log(res);

  // Update saved tooltip
  const saved = document.getElementById("saved");
  if (!saved) return;
  saved.setAttribute("data-bs-original-title", "Sidst gemt: " + new Date().toLocaleTimeString());
  saved.innerText = "✅";
  setTimeout(() => {
    saved.innerText = "💾";
  }, 1000);
}

export function lockPage(options = { lockEditor: true, filterPage: true, showLoader: true, disableButtons: true }) {
  // 🔐 Lock editor
  if (options.lockEditor) {
    const results = document.getElementById("results");
    if (results) results.setAttribute("contenteditable", false);
  }

  // 🏡 Add filter to main page
  if (options.filterPage) {
    const home = document.getElementById("home");
    if (home) home.classList.add("filter");
    else document.querySelector("body").classList.add("filter");
  }

  // 🔘 Disable all buttons
  if (options.disableButtons) {
    const buttons = document.querySelectorAll("button,a.btn");
    if (buttons)
      buttons.forEach((button) => {
        button.disabled = true;
        button.setAttribute("disabled", true);
      });
  }

  // Show spinner
  const spinner = document.getElementById("spinner");
  if (spinner) spinner.classList.remove("d-none");

  // 🌀 Add loader
  if (options.showLoader) {
    const loader = document.getElementById("loader");
    if (loader) loader.hidden = false;
  }
}

export function unlockPage(editableResults = true) {
  // 🔐 Unock editor
  if (editableResults) {
    const results = document.getElementById("results");
    if (results) results.setAttribute("contenteditable", true);
  }

  // 🏡 Remove filter to main page
  const home = document.getElementById("home");
  if (home) home.classList.remove("filter");
  document.querySelector("body").classList.remove("filter");

  // 🌀 Remove loader
  const loader = document.getElementById("loader");
  if (loader) loader.hidden = true;

  // 🔘 Enable all buttons
  const buttons = document.querySelectorAll("button,a.btn");
  if (buttons) buttons.forEach((button) => button.setAttribute("disabled", false));
  if (buttons) buttons.forEach((button) => (button.disabled = false));

  // 💨 Remove all instances of .blur
  const blur = document.querySelectorAll(".blur");
  if (blur) blur.forEach((element) => element.classList.remove("blur"));

  // Stop all spinners
  const spinners = document.querySelectorAll(".spinner-border");
  if (spinners) spinners.forEach((spinner) => spinner.classList.add("d-none"));
}

export function initClearAllFields() {
  const clearAllFields = document.getElementById("clearAllFields");
  if (!clearAllFields) return;

  clearAllFields.addEventListener("click", () => {
    const allFields = getAllFields();
    allFields.forEach((id) => {
      const el = document.getElementById(id);
      if (!el) return;

      if (el.tagName === "INPUT" || el.tagName === "TEXTAREA") {
        el.value = "";
      } else {
        el.innerText = "";
      }
    });
  });
}

export function copyToClipboard(text) {
  // If no text, bail with warning
  if (!text) return showWarning("No text to copy");

  const el = document.createElement("textarea");
  el.value = text;
  document.body.appendChild(el);
  el.select();

  if (navigator.clipboard) {
    // Use the navigator clipboard API if it's available
    console.log("Using navigator clipboard API");
    navigator.clipboard
      .writeText(text)
      .then(() => {
        document.body.removeChild(el);
        console.log("Copied to clipboard with navigator.clipboard");
        showSuccess("Saved in your clipboard");
      })
      .catch((error) => {
        console.error("Failed to copy to clipboard:", error);
        fallbackCopyToClipboard(text, el);
      });
  } else {
    // Fallback to the execCommand method if navigator clipboard API is not available
    fallbackCopyToClipboard(text, el);
  }
}

function fallbackCopyToClipboard(text, el) {
  try {
    const successful = document.execCommand("copy");
    if (successful) {
      document.body.removeChild(el);
      showSuccess("Saved in your clipboard");
    } else {
      throw new Error("Failed to copy to clipboard");
    }
  } catch (error) {
    console.error("Failed to copy to clipboard:", error);
  }
}

export function initPagination(firstIsActive = false) {
  const allLiA = document.querySelectorAll("a.page-link");
  allLiA.forEach((a) => {
    a.addEventListener("click", (e) => {
      allLiA.forEach((a) => a.classList.remove("active"));

      // Add active class to this li
      e.target.classList.add("active");
    });
  });

  // Add active class to second li (First is original)
  if (firstIsActive) allLiA[1].classList.add("active");
}

export function initScrambling() {
  const scramblingText = document.getElementById("scramblingText");
  const text = document.getElementById("text");

  if (!scramblingText || !text) return console.error("Missing elements");

  // Duplicate text into scramblingText on key
  text.addEventListener("keyup", (e) => {
    scramblingText.innerText = e.target.innerText;
  });

  // Duplicate on paste
  text.addEventListener("paste", (e) => {
    setTimeout(() => {
      scramblingText.innerText = e.target.innerText;
    }, 100);
  });
}

export var Scrambler = (function () {
  "use strict";
  function t(t, e) {
    return n(t) || o(t, e) || f();
  }
  function e(t) {
    return r(t) || a(t) || i();
  }
  function r(t) {
    if (Array.isArray(t)) {
      for (var e = 0, r = new Array(t.length); e < t.length; e++) r[e] = t[e];
      return r;
    }
  }
  function n(t) {
    if (Array.isArray(t)) return t;
  }
  function a(t) {
    if (Symbol.iterator in Object(t) || "[object Arguments]" === Object.prototype.toString.call(t)) return Array.from(t);
  }
  function o(t, e) {
    var r = [],
      n = !0,
      a = !1,
      o = void 0;
    try {
      for (var i, f = t[Symbol.iterator](); !(n = (i = f.next()).done) && (r.push(i.value), !e || r.length !== e); n = !0);
    } catch (t) {
      (a = !0), (o = t);
    } finally {
      try {
        n || null == f.return || f.return();
      } finally {
        if (a) throw o;
      }
    }
    return r;
  }
  function i() {
    throw new TypeError("Invalid attempt to spread non-iterable instance");
  }
  function f() {
    throw new TypeError("Invalid attempt to destructure non-iterable instance");
  }
  function u(t, e) {
    return new Promise(function (r, n) {
      if ((void 0 === t && n("Target element is undefined"), "true" !== t.getAttribute("data-scramble-active"))) {
        e.beforeEach && e.beforeEach(t), t.setAttribute("data-scramble-active", "true"), t.classList.add("scrambling");
        var a,
          o = t.innerHTML,
          i = [],
          f = e.speed ? e.speed : 100,
          u = t.textContent.split(""),
          c = u,
          d = t.textContent.split(""),
          b = !1;
        h(e.text) ? (a = x(e.text)) : t.getAttribute("data-scramble-text") && "" !== t.getAttribute("data-scramble-text") && (a = x(t.getAttribute("data-scramble-text"))), a && ((b = !0), (u = a.truth), (d = a.newLetters), (c = a.startText));
        var v = function () {
          if (
            (c.map(function (e, r) {
              return !(" \t\n\r\v".indexOf(e) > -1) && ((d[r] = g()), !0 === i[r] && (d[r] = u[r]), (t.textContent = d.join("")), !0);
            }),
            y(d, u))
          ) {
            if (((t.innerHTML = o), b)) {
              var n = t.children[0];
              n && "" !== n ? (n.textContent = d.join("")) : (t.textContent = d.join(""));
            }
            clearInterval(m), t.setAttribute("data-scramble-active", "false"), t.classList.remove("scrambling"), e.afterEach && e.afterEach(t), r(t);
          }
        };
        !(function (t) {
          if (t && s(t))
            for (var r = 0; r <= t.length; r++)
              !(function (t) {
                setTimeout(function () {
                  i[t] = !0;
                }, A(l(e), e.random, e.speed));
              })(r);
        })(c),
          v();
        var m = setInterval(function () {
          v();
        }, f);
      } else n("Animation already triggered");
    });
  }
  function c(t) {
    if (!m(t)) return !1;
    var r = p(t, l(t)),
      n = e(l(r) ? document.querySelectorAll(r.target) : document.querySelectorAll(r)),
      a = [];
    if (
      (n.forEach(function (t) {
        var e = u(t, r);
        a.push(e);
      }),
      !(a.length > 0))
    )
      return !1;
    r.beforeAll && r.beforeAll(n),
      Promise.all(a)
        .then(function (t) {
          r.afterAll && r.afterAll(t);
        })
        .catch(function (t) {
          r.errorHandler && r.errorHandler(t);
        });
  }
  var l = function (t) {
      return !!t && t.constructor === Object;
    },
    s = function (t) {
      return !!t && t.constructor === Array;
    },
    d = function (t) {
      return "boolean" == typeof t;
    },
    b = function (t) {
      return "function" == typeof t;
    },
    v = function (t) {
      return Number.isInteger(t);
    },
    h = function (t) {
      return !(!t || "" === t || !("string" == typeof t || t instanceof String));
    },
    m = function (t) {
      return !s(t) && !d(t) && "number" != typeof t && "function" != typeof t && void 0 !== t;
    },
    g = function (t, e) {
      var r = t || 1,
        n = e || !1,
        a = Math.random()
          .toString(36)
          .replace(/[^a-z]+/g, "")
          .substr(0, r);
      return " \t\n\r\v".indexOf(a) < 0 && !0 !== n && a;
    },
    A = function (e, r, n) {
      var a = e || !1,
        o = n || 100;
      if (a && s(r) && r.length > 1) {
        var i = t(r, 2),
          f = i[0],
          u = i[1];
        if (((n >= u || o >= u) && (o = u - 1), (u -= o), f > u && (f = u), v(f) && v(u))) return Math.floor(Math.random() * (u - f)) + f;
      }
      return Math.floor(1999 * Math.random()) + 1e3;
    },
    p = function (t, e) {
      var r = e || !1,
        n = {
          target: "[data-scrambler]",
          random: [1e3, 3e3],
          speed: 100,
          text: !1,
          beforeEach: !1,
          afterEach: !1,
          beforeAll: !1,
          afterAll: !1,
          errorHandler: !1,
        };
      return t && r && ((n.target = void 0 !== t.target ? t.target : "[data-scrambler]"), (n.random = void 0 !== t.random ? t.random : [1e3, 3e3]), (n.speed = void 0 !== t.speed ? t.speed : 100), (n.text = void 0 !== t.text && t.text), (n.beforeEach = !(void 0 === t.beforeEach || !b(t.beforeEach)) && t.beforeEach), (n.afterEach = !(void 0 === t.afterEach || !b(t.afterEach)) && t.afterEach), (n.beforeAll = !(void 0 === t.beforeAll || !b(t.beforeAll)) && t.beforeAll), (n.afterAll = !(void 0 === t.afterAll || !b(t.afterAll)) && t.afterAll), (n.errorHandler = !(void 0 === t.errorHandler || !b(t.errorHandler)) && t.errorHandler)), n;
    },
    y = function (t, e) {
      return !(
        t.length !== e.length ||
        !t.every(function (t, r) {
          return t === e[r];
        })
      );
    },
    x = function (t) {
      if (!t || void 0 === t || !("string" == typeof t || t instanceof String)) return !1;
      var e,
        r = t,
        n = r.split(""),
        a = r.split(""),
        o = [];
      return (
        n.forEach(function (t, e) {
          " \t\n\r\v".indexOf(n[e]) > -1 ? o.push(" ") : o.push(g());
        }),
        (e = o),
        { truth: n, newLetters: a, startText: e }
      );
    };
  return (function () {
    return c;
  })();
})();
